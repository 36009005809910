<template>
  
    <!-- Case Start -->
    <div class="container-fluid bg-light py-5">
        <div class="container py-5">
            <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
                <!-- <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Case Study</div> -->
                <h1 class="mb-4">Explore Our AI Tools</h1>
            </div>
            <div class="row g-4">
                <div class="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                    <div class="case-item position-relative overflow-hidden rounded mb-2">
                        <img class="img-fluid" src="@/assets/project-1.jpg" alt="">
                        <a class="case-overlay text-decoration-none" href="">
                            <small>Customizable Chatbots</small>
                            <h5 class="lh-base text-white mb-3">Transform customer interactions with tailored chatbots trained on your specific documents and data.
                            </h5>
                            <span class="btn btn-square btn-primary"><i class="fa fa-arrow-right"></i></span>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                    <div class="case-item position-relative overflow-hidden rounded mb-2">
                        <img class="img-fluid" src="@/assets/project-2.jpg" alt="">
                        <a class="case-overlay text-decoration-none" href="">
                            <small>Copilot</small>
                            <h5 class="lh-base text-white mb-3">Get assistance from Copilot, which is trained on your data, directly within your browser tab, whenever you need it.
                            </h5>
                            <span class="btn btn-square btn-primary"><i class="fa fa-arrow-right"></i></span>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
                    <div class="case-item position-relative overflow-hidden rounded mb-2">
                        <img class="img-fluid" src="@/assets/project-3.jpg" alt="">
                        <a class="case-overlay text-decoration-none" href="">
                            <small>Data Visulaization</small>
                            <h5 class="lh-base text-white mb-3"> Unlock actionable insights and enhance decision-making through powerful and intuitive data visualization tools.
                            </h5>
                            <span class="btn btn-square btn-primary"><i class="fa fa-arrow-right"></i></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Case End -->


</template>

<script>
export default {
name: 'Products',
props: {}
}
</script>

<style>

</style>