import { createRouter, createWebHistory } from 'vue-router'
import MainHome from '@/views/MainHome.vue'
import About from '@/views/about/About.vue'
import Contact from '@/views/contact/Contact.vue'
import Home from '@/views/home/HomeView.vue'
import Services from '@/views/services/Services.vue'
import Login from '@/views/login/Login.vue'
import Signup from '@/views/register/Register.vue'
import ServiceOfferes from '@/views/ServiceOffers/ServiceOffers.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/services',
    name: 'services',
    component: Services
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/service-offers',
    name: 'serviceOffers',
    component: ServiceOfferes
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
