<template>

  <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
   <NavBar2 title="Contact Us"/> 
   <contact/>
    <NewsletterSub/>
    <Footer/>

</template>

<script>
// @ is an alias to /src
import NavBar2 from '@/components/NavBar/NavBar2.vue'
import NewsletterSub from '@/components/NewsletterSub/NewsletterSubs.vue'
import Footer from '@/components/Footer/Footer.vue'
import Contact from '@/components/Contacts/Contacts.vue'
export default {
name: 'Contacts',
components: {
  NavBar2,
  NewsletterSub,
  Footer,
  Contact
}
}
</script>
