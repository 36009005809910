<template>
  
    <!-- About Start -->
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div class="about-img">
                        <img class="img-fluid" src="@/assets/about-img.jpg">
                    </div>
                </div>
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div>
                    <h1 class="mb-4">Use Our Tools To Transform Your Business To Fully Automation </h1>
                    <p class="mb-4">Our AI tool can seamlesly integrate with different existing of your services</p>
                    <p class="mb-4">Train your own custom chat bot and have an insightfull conversation with your data, not only you
                        invite your workers event your clients to use your chat bot and understand what service exactly you are providing.</p>
                    <div class="row g-3">
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Customizable</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Fast Latency</h6>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>High Security</h6>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <RouterLink class="btn btn-primary rounded-pill px-4 me-3" to="/about">Read More</RouterLink>
                        <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-instagram"></i></a>
                        <!-- <a class="btn btn-outline-primary btn-square" href=""><i class="fab fa-linkedin-in"></i></a> -->
                        <a class="btn btn-outline-primary btn-square me-3" href="https://www.linkedin.com/company/102183963/" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin-in"></i></a>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->

</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    name: 'About',
    props: {

    }

}
</script>

<style>

</style>