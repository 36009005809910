<template>

  <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
   <NavBar2 title="About Us"/> 
   <About/>
   <Services/> 
   <Features/> 
   <Products/> 
   <Teams/> 
   <!-- <testimonial/>  -->
    <NewsletterSub/>
    <Footer/>

</template>

<script>
// @ is an alias to /src
import NavBar2 from '@/components/NavBar/NavBar2.vue'
import About from '@/components/About/about.vue'
import Services from '@/components/Services/Services.vue'
import Features from '@/components/Features/Features.vue'
import Products from '@/components/Products/Products.vue'
import FAQ from '@/components/FAQ/FAQ.vue'
import Teams from '@/components/Teams/Teams.vue'
import Testimonial from '@/components/Testimonial/Testimonial.vue'
import NewsletterSub from '@/components/NewsletterSub/NewsletterSubs.vue'
import Footer from '@/components/Footer/Footer.vue'
export default {
name: 'Abouts',
components: {
  NavBar2,
  About,
  Services,
  Features,
  Products,
  FAQ,
  Teams,
  Testimonial,
  NewsletterSub,
  Footer
}
}
</script>
