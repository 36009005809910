<template>
<div class="row justify-content-center vh-100 align-items-center">
    <div class="col-xl-10 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow-lg">
            <div class="card-body p-0">
                <!-- Nested Row within Card Body -->
                <div class="row">
                    <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
                    <div class="col-lg-7">
                        <div class="p-5">
                            <div class="text-center">
                                <h1 class="h4 text-gray-900 mb-4">Create an Account!</h1>
                            </div>
                            <form @submit.prevent="handleSubmit" class="user">
                                <div class="form-group row">
                                    <div class="col-sm-6 mb-3 mb-sm-0">
                                        <input v-model="userName" type="text" class="form-control form-control-user" id="FirstName"
                                            placeholder="Name" required>
                                    </div>
                                    <div class="col-sm-6">
                                        <input v-model="companyName" type="text" class="form-control form-control-user" id="LastName"
                                            placeholder="Companey Name">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input v-model="email" class="form-control form-control-user" id="InputEmail"
                                        placeholder="Email Address" required>
                                </div>
                                <div class="form-group row">
                                    <div  class="col-sm-6 mb-3 mb-sm-0">
                                        <input v-model="password" type="password" class="form-control form-control-user"
                                            id="InputPassword" placeholder="Password" required>
                                    </div>
                                    <div class="col-sm-6">
                                        <input v-model="reapeatPassword"  type="password" class="form-control form-control-user"
                                            id="RepeatPassword" placeholder="Repeat Password" required>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary btn-user btn-block">
                                    Register Account
                                </button>
                                <hr>
                                <!-- <button type="button" class="btn btn-google btn-user btn-block">
                                    <i class="fab fa-google fa-fw"></i> Register with Google
                                </button> -->
                                <!-- <button type="button" class="btn btn-facebook btn-user btn-block">
                                    <i class="fab fa-facebook-f fa-fw"></i> Register with Facebook
                                </button> -->
                                <p id="errormessage">{{ errorMessage }}</p>
                            </form>
                            <hr>
                            <div class="text-center">
                                <a class="small" href="">Forgot Password?</a>
                            </div>
                            <div class="text-center">
                                <router-link class="small" to="/login">Already have an account? Login!</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    name: 'Signup',
    data(){
        return {
        email: '',
        password: '',
        reapeatPassword: '', 
        userName: '',
        companyName: '',
        errorMessage: ''
        }
    },
    methods: {
        async handleSubmit() {
    console.log('hello');
    if(this.reapeatPassword != this.password){
        this.errorMessage = "The passwords are different, must be similar"
        return 
    }
    try {
        const data = {
            type: "register",
            email: this.email,
            password: this.password,
            user_name: this.userName,
            company_name: this.companyName
        };

        const res = await fetch("http://127.0.0.1:5000/register", {
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
            method: "POST",
            mode: "cors",
            credentials: "omit"
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        } else {
            const response = await res.json();
            console.log('Response:', response);
            if (response.registration === "success") { 
                // Uncomment the line below if you want to navigate to another route after successful login
                this.$router.push('/login');
            } else {
                console.error('Login failed:', response.error);
            }
        }
    } catch (error) {
        console.error('Error during login:', error);
    }
}


    }
}
</script>

<style>
.bg-register-image {
    background: url('@/assets/data_pilot.png') no-repeat center center;
    background-size: cover;
}
.form-control-user {
    border-radius: 10px;
}
.btn-google {
    background-color: #ea4335;
    color: white;
}
.btn-facebook {
    background-color: #3b5998;
    color: white;
}
#errormessage{
    color: red;
}
</style>
