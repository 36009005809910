<template>
    <div class="bs-primary">
  <div class="container">

<!-- Outer Row -->
<div class="row justify-content-center vh-100 align-items-center">

<div class="col-xl-10 col-lg-12 col-md-9">

    <div class="card o-hidden border-0 shadow-lg">
        <div class="card-body p-0">
            
            <!-- Nested Row within Card Body -->
            <div class="row">
                <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                <div class="col-lg-6">
                    <div class="p-5">
                        <div class="text-center">
                            <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                        </div>
                        <form @submit.prevent="handleSubmit" class="user">
                            <div class="form-group">
                                <input v-model="email" type="email" class="form-control form-control-user"
                                    id="exampleInputEmail" aria-describedby="emailHelp"
                                    placeholder="Enter Email Address...">
                            </div>
                            <div class="form-group">
                                <input v-model="password" type="password" class="form-control form-control-user"
                                    id="exampleInputPassword" placeholder="Password">
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-checkbox small">
                                    <input type="checkbox" class="custom-control-input" id="customCheck">
                                    <label class="custom-control-label" for="customCheck">Remember
                                        Me</label>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary btn-user btn-block">
                                Login
                            </button>
                            <!-- <hr>
                            <button type="button" class="btn btn-google btn-user btn-block">
                                <i class="fab fa-google fa-fw"></i> Login with Google
                            </button>
                            <button type="button" class="btn btn-facebook btn-user btn-block">
                                <i class="fab fa-facebook-f fa-fw"></i> Login with Facebook
                            </button> -->
                        </form>
                        <hr>
                        <div class="text-center">
                            <a class="small" href="#">Forgot Password?</a>
                        </div>
                        <div class="text-center">
                            <RouterLink class="small" to="/signup">Create an Account!</RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

</div>



</div>
</div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    name: 'Login',
    data(){
        return {
        email: '',
        password: ''
        }
    },
    methods: {
        async handleSubmit() {
    console.log('hello');
    try {
        const data = {
            type: "login",
            email: this.email,
            password: this.password
        };

        const res = await fetch("http://127.0.0.1:5000/login", {
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
            method: "POST",
            mode: "cors",
            credentials: "omit"
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        } else {
            const response = await res.json();
            console.log('Response:', response);
            if (response.login === "success") { 
                localStorage.setItem('token', response.token);
                // Uncomment the line below if you want to navigate to another route after successful login
                this.$router.push('/service-offers');
                console.log('Token saved:', response.token);
            } else {
                console.error('Login failed:', response.error);
            }
        }
    } catch (error) {
        console.error('Error during login:', error);
    }
}


    }


}
</script>

<style>
.bg-login-image {
background: url('@/assets/data_pilot.png') no-repeat center center;
background-size: cover;
}
.form-control-user {
border-radius: 10px;
}
.btn-google {
background-color: #ea4335;
color: white;
}
.btn-facebook {
background-color: #3b5998;
color: white;
}
</style>

