<template>
  
    <!-- Service Start -->
    <div class="container-fluid bg-light mt-5 py-5">
        <div class="container py-5">
            <div class="row g-5 align-items-center">
                <div class="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                    <RouterLink to="/services">
                    <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Services</div></RouterLink>
                    <h1 class="mb-4">Our Excellent AI Tools for Your Business</h1>
                    <p class="mb-4">Our platform offers a suite of powerful AI tools designed to enhance your business operations. 
                        From customizable chatbots that can be trained with your specific documents and data, to data analysis AI tools that
                         provides deep insights with visually appealing graphs, our tools are built to meet your unique needs. </p>
                    <!-- <a class="btn btn-primary rounded-pill px-4" href="">Read More</a> -->
                </div>
                <div class="col-lg-7">
                    <div class="row g-4">
                        <div class="col-md-6">
                            <div class="row g-4">
                                <div class="col-12 wow fadeIn" data-wow-delay="0.1s">
                                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon bg-dark btn-square">
                                            <i class="fa bi-clock-history fa-2x text-white"></i>
                                            <!-- <i class="bi bi-headset text-white"></i> -->

                                        </div>
                                        <h5 class="mb-3">24/7 Customer Support</h5>
                                        <p>Provide round-the-clock assistance to your customers, ensuring their queries are addressed promptly, 
                                            regardless of the time zone. This improves customer satisfaction and loyalty. </p>
                                        <!-- <a class="btn px-3 mt-auto mx-auto" href="">Read More</a> -->
                                    </div>
                                </div>
                                <div class="col-12 wow fadeIn" data-wow-delay="0.5s">
                                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon bg-dark btn-square">
                                            <i class="fa bi-lightning-fill text-white fa-2x"></i>
                                        </div>
                                        <h5 class="mb-3">Increased Efficiency</h5>
                                        <p>Automate routine tasks and FAQs, allowing your human agents to focus on more complex issues. 
                                            This boosts overall productivity and reduces operational costs.</p>
                                        <!-- <a class="btn px-3 mt-auto mx-auto" href="">Read More</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pt-md-4">
                            <div class="row g-4">
                                <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon bg-dark btn-square">
                                            <i class="fa bi-person-lines-fill fa-2x text-white"></i>
                                        </div>
                                        <h5 class="mb-3">Personalized User Experience</h5>
                                        <p>Our chatbot uses advanced natural language processing to understand and respond to customer 
                                            inquiries in a personalized manner. It can remember previous interactions and preferences, 
                                            creating a more tailored user experience.
                                        </p>
                                        <!-- <a class="btn px-3 mt-auto mx-auto" href="">Read More</a> -->
                                    </div>
                                </div>
                                <div class="col-12 wow fadeIn" data-wow-delay="0.7s">
                                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon bg-dark btn-square ">
                                            <i class="fa bi-graph-up fa-2x text-white"></i>
                                        </div>
                                        <h5 class="mb-3">Scalability</h5>
                                        <p>Easily handle a high volume of inquiries during peak times without compromising on response quality. Our chatbot 
                                            scales effortlessly with your business, ensuring consistent and reliable customer support.</p>
                                        <!-- <a class="btn px-3 mt-auto mx-auto" href="">Read More</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Service End -->

</template>

<script>
export default {
name: 'Services'
}
</script>

<style>

</style>